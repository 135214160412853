/* Full height fix for html and body */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
}

/* General Container */
.container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0;
}

/* Header styles */
.header-pricing {
    padding: 0;
    margin: 0; /* Remove any margin */
    text-align: center;
}

.header-pricing h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.header-pricing p {
    font-size: 1.0rem;
    color: #666;
    width: 100%;
    margin: 10 auto;
    margin-bottom: 30px;
}

/* Flexbox Row for Plans */
.row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 0; /* Ensure no extra margin at the bottom */
}

/* Columns for each plan */
.col {
    flex: 1;
    max-width: 30%;
    margin: 0;
}

/* Card styles */
.card {
    position: relative; /* Ensure it contains the active badge */
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    padding: 20px 15px;
    text-align: center;
    z-index: 1; /* Make sure the card is above other background elements */
}

.card:hover {
    transform: translateY(-10px);
    z-index: 2; /* Ensure it hovers above other elements */
}

/* Navbar z-index (in case it's causing the issue) */
.navbar {
    position: relative;
    z-index: 10; /* Ensure the navbar stays on top of everything except the cards */
}

/* Card header */
.card-header {
    background-color: #87dc5d;
    padding: 1px 0;
    border-radius: 10px 10px 0 0;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #ffffff;
}

/* Plan Price */
.card-body h1 {
    font-size: 2.5rem;
    margin-bottom: 25px;
    color: #333;
}

.card-body h1 small {
    font-size: 1rem;
    color: #777;
}

/* List of features */
.card-body ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
}

.card-body ul li {
    font-size: 0.8rem;
    margin-bottom: 10px;
    color: #555;
}

/* Button styles */
.btn {
    display: inline-block;
    font-size: 1.125rem;
    font-weight: 600;
    padding: 10px 0;
    width: 100%;
    background-color: #28a745;
    color: white;
    border-radius: 5px;
    border: 1px solid #28a745;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.btn:hover {
    background-color: #218838;
    border-color: #1e7e34;
}

/* Button outline styles */
.btn-outline-success {
    background-color: transparent;
    border-color: #28a745;
    color: #28a745;
}

.btn-outline-success:hover {
    background-color: #28a745;
    color: #fff;
}

/* Pricing.css */
.btn[disabled] {
    background-color: #cccccc;
    border-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    pointer-events: none;
}

.btn-outline-success[disabled] {
    background-color: #cccccc;
    border-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    pointer-events: none;
}

/* Active badge CSS */
.active-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red; /* Badge color */
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
    z-index: 10; /* Ensure the badge is on top */
}

/* Responsive Design */
@media (max-width: 768px) {
    .row {
        flex-direction: column;
        align-items: center;
        padding: 0 10px; /* Add padding to the row to create spacing on the sides */
    }

    .col {
        width: 100%; /* Make sure the column takes the full width of the container */
        max-width: 100%; /* Ensure the column doesn't have any max-width restrictions */
        margin: 10px 0; /* Add margin between cards vertically */
    }

    .container {
        padding: 20px; /* Remove padding on container */
    }

    .header-pricing h1 {
        font-size: 2rem;
    }

    .header-pricing p {
        width: 100%;
    }

    .card-body h1 {
        font-size: 2rem;
    }

    .card-body ul li {
        font-size: 0.875rem;
    }
}

/* Footer Links */
.footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    font-size: 14px;
    color: #888;
}

.footer-links span {
    margin: 0 10px;
}

.footer-link {
    margin-left: 10px;
    color: #888;
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: underline;
    color: #333;
}

.footer-separator {
    margin: 0 15px;
    color: #ccc;
}