.report-page {
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
  font-size: 12px;
}

.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 12px;
}

.report-title {
  color: #fc2400;
  font-size: 18px;
  font-weight: bold;
}

.report-info-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-bottom: -3px;
  cursor: pointer;
}

.report-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.report-h2 {
  margin-left: 0px;
  color: #fc2400;
  font-size: 12px;
  font-weight: bold;
}

.report-card {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 160px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  font-size: 12px;
}

.report-card .input {
  position: absolute;
  top: 10px;
  right: 10px;
}

.report-checkbox-container {
  position: absolute;
  top: 10px;
  right: 20px;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.report-checkbox-container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.report-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
  border: 0.2px solid #cbc8c8;
}

.report-checkbox-container input:checked ~ .report-checkmark {
  background-color: #fc2400;
}

.report-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.report-checkbox-container input:checked ~ .report-checkmark:after {
  display: block;
}

.report-checkbox-container .report-checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.report-download-button {
  display: flex;
  align-items: center;
  background-color: white;
  border: 2px solid #fc2400;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.report-download-button img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.report-download-button:hover {
  background-color: #ff8300;
  border-color: #ff8300;
  color: white;
}

.report-download-button:hover img {
  filter: invert(100%);
}

.report-no-reports {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  font-size: 36px;
  font-weight: bold;
  color: #888;
  opacity: 0.5;
}

.report-card-logo {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
}


.report-divider {
  position: relative;
  width: 30%; /* Adjust the width as needed */
  height: 5px;
  background-color: hsl(0, 90%, 47%); /* Adjust color as needed */
  margin: 10px auto; /* Center the divider horizontally */
  left: 0;
  right: 0;
  margin-top: 60px;
  margin-bottom: 20px;
}

/* Modal Styles */
.report-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.report-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.report-modal-content h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.report-modal-content p {
  font-size: 14px;
  margin-bottom: 20px;
  text-align: justify;
}

.report-modal-close-button {
  background-color: #fc2400;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

@media (max-width: 720px) {
  .report-container {
    margin-bottom: 200px;
  }
  .report-page {
    padding: 20px;
    font-size: 10px;
    overflow-y: auto; /* Enable vertical scrolling */
    margin-bottom: 150px;
  }

  .report-header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .report-title {
    font-size: 16px;
    text-align: center;
    margin-bottom: 0px;
  }

  .report-info-icon {
    width: 18px;
    height: 18px;
    margin-bottom: 0;
    margin-bottom: -2.5px;
  }

  .report-container {
    flex-direction: column;
    gap: 5px;
  }

  .report-h2 {
    font-size: 14px;
    margin-left: 0;
  }

  .report-card {
    width: 100%;
    height: auto;
    padding: 5px 10px;
    margin: 5px; /* Added margin for space around the card */
    font-size: 11px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-left: -10px;
  }

  .report-checkbox-container {
    top: 5px;
    right: 5px;
    width: 15px;
    height: 15px;
  }

  .report-checkbox-container input:checked ~ .report-checkmark {
    background-color: #fc2400;
  }

  .report-checkmark {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 0.2px solid #cbc8c8;
    margin-left: -5px;
  }

  .report-checkbox-container .report-checkmark:after {
    left: 5px;
    top: 2.5px;
    width: 3px;
    height: 6px;
    border-width: 0 2px 2px 0;
  }

  .report-download-button {
    padding: 8px 16px;
    font-size: 11px;
    display: flex;
    align-self: flex-end;
    margin-top: -25px;
    margin-bottom: 10px;
  }

  .report-download-button img {
    width: 12px;
    height: 12px;
  }

  .report-no-reports {
    font-size: 24px;
    height: 40vh;
  }

  .report-card-logo {
    bottom: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
  }

  .report-modal-overlay {
    align-items: flex-start;
  }

  .report-modal-content {
    margin-top: 100px;
    width: 100%;
    max-width: 350px;
    padding: 15px;
  }

  .report-modal-content h2 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .report-modal-content p {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .report-modal-close-button {
    padding: 8px 16px;
    font-size: 12px;
  }

  .report-divider {
    position: relative;
    width: 50%; /* Adjust the width as needed */
    height: 5px;
    background-color: hsl(0, 90%, 47%); /* Adjust color as needed */
    margin: 10px auto; /* Center the divider horizontally */
    left: 0;
    right: 0;
    margin-top: -150px;
    margin-bottom: 20px;
  }
}

/* Backdrop for the loading spinner */
.loading-backdrop {
  position: fixed; /* Ensure it covers the entire viewport */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 9999; /* Ensure it is on top of other content */
}

/* Loading text style */
.loading-text {
  margin-top: 10px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}


