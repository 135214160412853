/* General styling */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.forgot-password-container {
  display: flex;
  background: linear-gradient(to bottom, #fea239, #ff8376);
  height: 100vh;
}

.forgot-password-left {
  flex: 3.1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.forgot-password-leftlogo1 {
  height: 100vh;
}

.forgot-password-right {
  flex: 1;
  background: linear-gradient(to bottom, #ffffff, #ffffff, #ffe5c7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 90px;
  margin-right: 90px;
}

.forgot-password-form {
  position: relative;
  width: 100%;
  max-width: 400px;
  text-align: center;
  left: -10px;
}

.forgot-password-right img {
  width: 175px;
  height: 60px;
  margin-bottom: 40px;
}

.forgot-password-h2 {
  font-size: 20px;
  margin-left: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.forgot-password-input-group {
  margin-bottom: 20px;
  text-align: left;
  margin-top: 40px;
}

.forgot-password-input-group label {
  display: block;
  margin-bottom: 8px;
  color: #494646;
  font-size: 14px;
  font-weight: bold;
}

.forgot-password-input-group input {
  width: 320px;
  height: 12px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #e7dfdf;
  border-radius: 5px;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.1);
}

.forgot-password-submit-button {
  width: 50%;
  height: 50px;
  padding: 12px;
  border-color: #c9574b;
  background: linear-gradient(to left, #ff8376, #fea239);
  color: white;
  font-size: 14px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 10px #ff837669;
  border: 1px solid #c9574b;
  border-radius: 15px;
  opacity: 1;
}

.forgot-password-submit-button:hover {
  background: linear-gradient(to left, #ff8376, #fea239);
}

.forgot-password-submit-button:disabled {
  cursor: not-allowed;
  background-color: #ccc; /* Change the background color to indicate it's disabled */
}

.forgot-password-back-to-signin {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
}

.forgot-password-back-to-signin a {
  color: #fea239;
  text-decoration: none;
  font-size: 16px;
}

.forgot-password-otp-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.forgot-password-otp-modal-content {
  position: relative;
  background: white;
  padding: 30px;
  border-radius: 20px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.forgot-password-otp-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fc2400;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgot-password-otp-close-button:hover {
  background-color: #d11f00;
}

.forgot-password-otp-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 25px;
}

.forgot-password-otp-inputs input {
  width: 60px;
  height: 60px;
  font-size: 20px;
  text-align: center;
  border: 1px solid #e7dfdf;
  border-radius: 10px;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.1);
}

.forgot-password-resend-otp {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-left: 30px;
  padding: 10px;
}

.forgot-password-resend-otp .resend-link {
  cursor: pointer;
  color: #fea239;
}

.forgot-password-resend-otp .disabled {
  cursor: not-allowed;
  color: grey;
}

.forgot-password-error-message {
  color: red;
  margin-top: 10px;
}

.forgot-password-success-message {
  color: green;
  margin-top: 10px;
}

.forgot-checkbox-container {
  display: flex;
  justify-content: start;
  margin-top: 20px;
  cursor: pointer;
  font-size: 12px;
}

.forgot-verify-checkbox {
  position: relative;
  width: 15px;
  height: 15px;
}

.forgot-verify-checkbox-label {
  position: relative;
  margin-top: 3px;
  margin-left: 2px;
}

.forgot-mandatory {
  color: red;
  font-weight: bold;
}

.password-field {
  position: relative;
}

.password-field input {
  padding-right: 40px; /* space for the icon */
}

.toggle-password-visibility {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 1105px) and (min-width: 320px) {
  .forgot-password-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height of the viewport */
    overflow: hidden; /* Prevent scrolling */
  }

  .forgot-password-left {
    display: none;
  }

  .forgot-password-right {
    flex: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 0;
  }

  .forgot-password-right img {
    width: 140px;
    height: 50px;
    margin-bottom: 20px;
  }

  .forgot-password-form {
    width: 100%;
    max-width: 400px;
    text-align: center;
    margin-bottom: 500px;
  }

  .forgot-password-input-group {
    margin-top: 20px;
  }

  .forgot-password-input-group label {
    margin-left: 13%;
    text-align: left;
    width: 60%;
    display: block;
  }

  .forgot-password-input-group input {
    width: 70%;
    font-size: 1rem;
    margin-left: 13%;
  }

  .forgot-password-submit-button {
    width: 50%;
    margin-top: 20px;
  }

  .forgot-password-back-to-signin {
    margin-top: -200px;
    font-size: 0.9rem;
  }

  .forgot-password-resend-otp .resend-link {
    font-size: 0.8rem;
  }
  .forgot-verify-checkbox {
    margin-left: 55px;
  }
}

@media screen and (max-width: 1105px) and (min-width: 320px) {
  .forgot-password-otp-modal {
    justify-content: center;
    align-items: center;
  }

  .forgot-password-otp-modal-content {
    max-width: 300px; /* Adjust the max height as needed */
    height: 250px; /* Set the height to 90% of the screen */
    justify-content: center;
    align-items: center;
  }

  .forgot-password-otp-inputs {
    margin-bottom: 20px;
    margin-left: 25px;
  }

  .forgot-password-otp-inputs input {
    width: 55px;
    height: 55px;
    font-size: 20px;
    text-align: center;
    border: 1px solid #e7dfdf;
    border-radius: 10px;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .forgot-password-resend-otp {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 10px;
  }

  .forgot-password-resend-otp .resend-link {
    cursor: pointer;
    color: #fea239;
  }

  .forgot-password-resend-otp .disabled {
    cursor: not-allowed;
    color: grey;
  }

  .forgot-password-submit-button {
    width: 50%;
    margin: 20px auto; /* Center the button and add some margin */
  }
}
