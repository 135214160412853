/* Base styles for the desktop view */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 18px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.menu-icon-container {
  display: none; /* Hide menu icon by default */
}

.menu-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.logo {
  height: 30px;
}

.nav-links {
  display: flex;
  gap: 100px;
}

.nav-item {
  text-decoration: none;
  color: black;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
}

.nav-item .icon {
  margin-right: 8px;
  height: 16px;
}

.nav-item.active {
  color: #fc2400;
}

.nav-item.active .icon {
  filter: invert(39%) sepia(100%) saturate(3483%) hue-rotate(356deg)
    brightness(101%) contrast(105%);
}

.active-icon {
  position: absolute;
  top: 21px;
  left: 50%;
  transform: translateX(-50%);
  height: 18px;
  width: 80px;
}

.nav-icons {
  display: flex;
  align-items: center;
  gap: 60px;
}

.help-icon,
.notification-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.user-icon {
  width: 35px;
  height: 35px;
  background-color: #ff8300;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  cursor: pointer;
}

.user-icon-placeholder {
  width: 35px;
  height: 35px;
}

.profile-menu {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1000;
}

.profile-menu-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  color: black;
  transition: background-color 0.3s;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}

.profile-menu-item img.logout-icon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.profile-menu-item:hover {
  background-color: #f0f0f0;
}

.notification-container {
  position: relative;
  cursor: pointer;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
}

.help-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.help-label {
  font-size: 10px;
  color: #ff8300;
  margin-top: 2px; /* Adjust the spacing between the icon and the label */
}
/* Media Query for Responsive Design */
@media (max-width: 1020px) {
  .nav-links,
  .logo {
    display: none; /* Hide main nav and logo on smaller screens */
  }

  .menu-icon-container {
    display: block; /* Show menu icon on smaller screens */
    cursor: pointer;
  }

  .menu-icon {
    width: 25px;
    height: 25px;
  }

  .nav-icons {
    flex-grow: 1; /* Allow icons to take remaining space */
    justify-content: flex-end; /* Align other icons to the right */
    gap: 20px; /* Adjust spacing between icons */
  }

  .help-icon-container {
    display: none; /* Hide the entire container on smaller screens */
  }

  .help-icon {
    display: none; /* Hide Guide icon on smaller screens */
  }

  .notification-container,
  .user-icon {
    display: flex; /* Ensure notification and user icons are visible */
    align-items: center;
    justify-content: center;
  }

  .notification-container {
    position: relative; /* Ensure badge is positioned correctly */
  }

  .notification-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 5px;
    font-size: 12px;
  }

  .user-icon {
    width: 35px;
    height: 35px;
    background-color: #ff8300;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    cursor: pointer;
  }
}
