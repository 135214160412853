.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.dropdown-options {
  position: absolute;
  z-index: 1000;
  background: white;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}

.dropdown-option {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #f1f1f1;
}

.dropdown-option.disabled {
  pointer-events: none;
  opacity: 0.5;
}
