.checkout-page {
    font-family: Arial, sans-serif;
    padding: 20px;
    display: flex;
    justify-content: center;
    background-color: white; /* Ensure the background is white */
    min-height: 100vh;
}

.checkout-container {
    display: flex;
    max-width: 1000px;
    width: 100%;
    box-shadow: none; /* Remove any shadow around the entire container */
    background-color: white; /* Ensure the background is white */
}

.checkout-left, .checkout-right {
    flex: 1;
    padding: 30px;
    box-sizing: border-box;
}

/* For the left section */
.checkout-left {
    background-color: white; /* Light gray background for the left section */
}

/* For the right section */
.checkout-right {
    background-color: white; /* White background for the right section */
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1); /* Create shadow on the left side of the right section */
}

/* No borders on any side */
.checkout-left,
.checkout-right {
    border: none; /* Remove any borders */
}


/* Container for back arrow and logo */
.checkout-back-logo-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.checkout-back-arrow {
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px;
}

.checkout-logo-section {
    display: flex;
    align-items: center;
}

.checkout-logo {
    width: 32px;
    height: 32px;
    margin-right: 8px;
}

.checkout-logo-text {
    font-size: 16px;
    font-weight: 500;
}

/* Price and subscription container */
.checkout-price-container {
    margin-bottom: 20px;
}

.checkout-header-title {
    font-size: 18px;
    font-weight: 600;
    color: #847575;
    margin-bottom: 10px;
    margin-top: 30px;
}

.checkout-price-details {
    display: flex;
    align-items: baseline;
    margin-top: 20px;
}

.checkout-price-value {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-right: 5px;
}

.checkout-price-period {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 12px;
    font-weight: 600;
    color: #666;
    line-height: 1.1;


}

.checkout-price-period span {
    text-align: left;
}

.checkout-subscription-details {
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
}

.checkout-subscription-details-title {
    display: block; /* Ensures this title is on its own line */
    font-weight: 600;
    margin-bottom: 5px; /* Adds space between title and billed */
}

.checkout-subscription-billed {
    display: block; /* Ensures this text is on its own line */
    color: #8f8989;
    font-size: 12px;
}

.checkout-pricing-summary {
    margin-top: 20px;
    border-top: 1px solid #ddd;
    padding-top: 20px;
}

.checkout-pricing-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.checkout-pricing-row.total {
    font-weight: bold;
    border-top: 1px solid #ddd;
    padding-top: 10px;
    margin-top: 10px;
    font-size: 18px;
}

.checkout-info-icon {
    margin-left: 5px;
    color: #999;
    font-size: 12px;
}

.checkout-divider {
    border-bottom: 1px solid #ddd;
    margin: 20px 0;
}

.checkout-form-group {
    margin-bottom: 15px;
}

.checkout-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    box-sizing: border-box;
}

.checkout-form-header {
    font-size: 18px;
    margin: 30px 0;
}

.checkout-input-card {
    margin-bottom: 10px;
}

.checkout-expiry-cvc {
    display: flex;
    gap: 10px;
}

.checkout-expiry-cvc input {
    flex: 1;
}

.checkout-city-pin {
    display: flex;
    gap: 10px;
}

.checkout-city-pin input {
    flex: 1;
}

.checkout-required {
    color: red;
    font-size: 12px;
    margin-left: 5px;
}

.checkout-save-info {
    border-radius: 5px;
    margin-bottom: 15px;
}

.checkout-checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.checkout-submit-btn {
    background-color: #28a745;
    color: white;
    padding: 15px;
    width: 100%;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
}

.checkout-submit-btn:hover {
    background-color: #218838;
}

/* Disabled button style */
.checkout-submit-btn.disabled {
    background-color: #ccc; /* Grey */
    cursor: not-allowed;
  }

  .checkout-required {
    color: red;
    font-size: 14px;
    margin-left: 5px;
}

.checkout-error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

/* Media Query for Mobile View */
@media (max-width: 768px) {
    .checkout-container {
        flex-direction: column; /* Stack the left and right sections vertically */
    }

    .checkout-left {
        order: 1; /* Left section goes to the top */
        margin-bottom: 20px; /* Add some space between sections */
    }

    .checkout-right {
        order: 2; /* Right section goes to the bottom */
        box-shadow: none; /* Remove shadow in mobile view */
        margin-top: -50px;
    }

    .checkout-submit-btn {
        font-size: 14px; /* Slightly smaller button text for mobile */
    }

    .checkout-logo {
        width: 24px; /* Smaller logo in mobile view */
        height: 24px;
    }

    .checkout-price-value {
        font-size: 28px; /* Adjust font size for the price */
    }
}
