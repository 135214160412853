.mylist {
  flex: 1;
  font-size: 12px;
}

.mylist-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mylist-h2 {
  color: #fc2400;
  font-size: 14px;
}

.mylist-refresh-icon {
  width: 18px;
  cursor: pointer;
  margin-right: 20px;
}

.mylist-table {
  background-color: white;
  padding: 25px;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  max-height: 255px;
  overflow-y: auto;
  font-size: 12px;
}

.mylist-table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 12px;
}

.mylist-table thead {
  display: table-header-group;
  width: 100%;
  table-layout: fixed;
  font-size: 12px;
}

.mylist-table tbody {
  display: table-row-group;
  width: 100%;
  font-size: 12px;
}

.mylist-table th,
.mylist-table td {
  padding: 10px;
  text-align: left;
  font-size: 12px;
}

.mylist-table th {
  border-bottom: 1.5px solid hsl(0, 0%, 49%);
  text-align: left;
  font-size: 12px;
}

.mylist-table td {
  padding: 8px;
  text-align: left;
  font-size: 12px;
}

.mylist-table input {
  width: 80px;
  margin-left: 10px;
  text-align: right;
  border: 1px solid #ddd;
  border-radius: 1px;
  padding: 8px;
  font-size: 12px;
}

.mylist-table input::-webkit-outer-spin-button,
.mylist-table input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mylist-table input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
  -webkit-appearance: none;  /* For Chrome, Safari, and newer versions of Opera */
  appearance: none;          /* Standard property */
}


.mylist-default-scrip {
  color: grey;
  opacity: 0.6;
  font-size: 12px;
}

/* Custom scrollbar styling */
.mylist-table::-webkit-scrollbar {
  width: 8px;
}

.mylist-table::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.mylist-table::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.mylist-table::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.mylist-optimise-section {
  background-color: white;
  padding: 20px;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  margin-top: 3px;
  font-size: 12px;
}

.mylist-optimise-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #fc2400;
  font-size: 14px;
  font-weight: bold;
}

.mylist-optimise-content {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.mylist-description {
  margin: 0;
  font-size: 11px;
  color: #666;
}

.mylist-span {
  font-size: 12px;
  font-family: sans-serif;
}

.mylist-expected-return {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;
}

.mylist-goal-checkbox {
  margin-right: 10px;
  opacity: 1;
  pointer-events: none; /* Prevent user interaction */
}

.mylist-disabled-label {
  opacity: 1;
}

.mylist-select-wrapper {
  position: relative;
  width: 180px;
  margin-left: 10px;
  font-size: 12px;
}

.mylist-select-box {
  width: 100%;
  padding: 13px 0;
  border: 1px solid hsl(0, 0%, 27%);
  border-radius: 1px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
  padding-right: 30px;
  font-size: 12px;
}

.mylist-dropdown-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  width: 12px;
  font-size: 12px;
}

.mylist-next-button {
  background-color: #fc2400;
  color: white;
  padding: 15px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
}

.mylist-next-button:hover {
  background-color: #d41a00;
}

.mylist-next-button:disabled {
  background-color: #ddd;
  color: #aaa;
  cursor: not-allowed;
}

.mylist-info-icon {
  width: 18px;
  cursor: pointer;
  margin-left: 5px;
  font-size: 12px;
}

.mylist-unit-input {
  text-align: right;
  font-size: 12px;
}

.mylist-disabled-input {
  opacity: 0.5;
  pointer-events: none;
  background-color: #f1f1f1;
}

/* Add Withdraw Section */
.mylist-card {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mylist-card-content {
  background: white;
  padding: 50px;
  border-radius: 12px;
  text-align: left;
  max-width: 650px;
  width: 100%;
  position: relative;
}

.mylist-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fc2400;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 26px;
  cursor: pointer;
}

.mylist-card-h2 {
  color: #fc2400;
  font-size: 16px;
  margin-bottom: 20px;
}

/* .mylist-input-container {
  margin-left: 160px;
} */

.mylist-input-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 100px;
}

.mylist-label {
  flex: 1;
  text-align: left;
}

.mylist-card .mylist-input {
  text-align: right;
}

.mylist-input {
  flex: 2;
  padding: 8px; /* Decreased padding for smaller input fields */
  width: 80px; /* Decreased width for smaller input fields */
  border: 1px solid #ddd;
  border-radius: 4px;
}

.mylist-input::-webkit-outer-spin-button,
.mylist-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mylist-input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
  -webkit-appearance: none;   /* For Chrome, Safari, and newer versions of Opera */
  appearance: none;           /* Standard property */
}

.mylist-notes-container {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}

.mylist-notes-label {
  flex: 1;
  text-align: left;
  font-weight: bold;
  margin-left: 40px;
  margin-top: 20px;
  font-size: 14px;
}



.mylist-notes p {
  margin: 10px 0;
}

.mylist-checkbox-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  cursor: pointer;
  font-size: 12px;
}

.mylist-verify-checkbox {
  position: relative;
  width: 15px;
  height: 15px;
}

.mylist-verify-checkbox-label {
  position: relative;
  margin-top: 3px;
  margin-left: 2px;
}

.mylist-submit-button-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.mylist-submit-button {
  background-color: #fc2400;
  color: white;
  padding: 15px 50px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.mylist-submit-button:hover {
  background-color: #d41a00;
}

.mylist-submit-button:disabled, 
.mylist-submit-button.disabled {
  background-color: #d3d3d3; /* Disabled color */
  cursor: not-allowed;
}

/* Custom scrollbar styling */
.mylist-table::-webkit-scrollbar {
  width: 8px;
}

.mylist-table::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.mylist-table::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.mylist-table::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Dropdown options custom scrollbar */
.dropdown-options::-webkit-scrollbar {
  width: 8px;
}

.dropdown-options::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.dropdown-options::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.dropdown-options::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.mylist-warning-message {
  color: red;
  font-size: 10px;
  margin-top: -15px;
  margin-left: 205px;
}

.point-one {
  color: red;
}
/*Var Test*/

.mylist-vartest-h2{
  color: #fc2400;
  font-size: 16px;
  margin: 20px 0;
  margin-top: 20px;
}

.mylist-vartest-checkbox-group {
  display: flex;
  gap: 10px; /* Adjust the gap between the checkboxes */
}

.mylist-vartest-note {
  margin: 20px 0;
  font-size: 12px; /* Adjust the font size */
  color: #fd0000; /* Adjust the color */
}


.mylist-vartest-checkbox-group {
  display: flex;
  gap: 30px; /* Adds space between the Yes and No options */
  align-items: center; /* Vertically aligns the radio buttons and text */
}

.mylist-radio-label {
  display: flex;
  align-items: center;
  gap: 5px; /* Adds space between the radio button and its label */
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.mylist-radio-label input[type="radio"] {
  width: 18px;
  height: 18px;
  border: 2px solid #fc2400; /* Custom border color */
  appearance: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

.mylist-radio-label input[type="radio"]:checked {
  background-color: #fc2400;
  border: 5px solid #ffffff; /* Inner circle white */
  box-shadow: 0 0 0 2px #fc2400; /* Outer blue ring */
}

.mylist-gearbox-disabled {
  opacity: 0.5; /* Make it look disabled */
  cursor: pointer; /* Change the cursor to indicate it’s disabled */
}


/* Advanced User Section */
.mylist-advanced-user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: -35px;
}

.mylist-advanced-title {
  font-size: 12px;
  color: #fc2400;
  margin-right: 10px;
  font-weight: bold;
}

.mylist-gearbox-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.mylist-advanced-content {
  background-color: #ffffff;
  padding: 0;
  border-radius: 8px;
  margin-top: -10px;
}

.mylist-checkbox-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.mylist-label-left {
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

/* Roi tabel */
.mylist-roi-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  margin-top: 20px;
}

.mylist-roi-table th,
.mylist-roi-table td {
  text-align: center;
  padding: 10px;
}

.mylist-roi-table th {
  font-weight: bold;
  font-size: 12px;
  color: white;
  background-color: hsl(0, 1%, 20%);
}

.roi-small-text {
  font-size: 10px;
  color: rgb(212, 206, 206);
  margin-top: 5px;
  font-weight: bold;
}

.mylist-roi-table td {
  background-color: #f9f9f9;
  font-size: 12px;
  font-weight: 600;
}

.mylist-roi-table input[type="checkbox"] {
  margin-right: 10px;
}

.roi-percentage {
  font-size: 12px;
  margin-left: 5px;
  color: #333;
  line-height: 1.5; /* Adjust line height */
  display: inline-block;
  position: relative;
  top: -2px; /* Fine-tune the vertical alignment */
}

.mylist-roi-table tr:nth-child(even) {
  background-color: #eee;
}



.mylist-percentage-right {
  flex: 1;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.mylist-note {
  margin-top: 30px;
  font-size: 12px;
  color: hsl(0, 0%, 0%);
  font-style: italic;
}

/* Save button placed at the right */
.mylist-save-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.mylist-save-button {
  background-color: #fc2400;
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.mylist-save-button:hover {
  background-color: #d41a00;
}

.mylist-save-button:disabled {
  background-color: #ddd;
  color: #aaa;
  cursor: not-allowed;
}

/* Media Query for 320px to 1020px screen widths */
@media (max-width: 1020px) {
  .mylist {
    flex: 1;
    font-size: 12px;
    margin-bottom: 90px;
  }

  .mylist-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
  }

  .mylist-h2 {
    font-size: 16px;
    margin: 0;
  }

  .mylist-refresh-icon {
    width: 18px;
    cursor: pointer;
    margin-right: 0;
  }

  .mylist-table {
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
    font-size: 12px;
  }

  .mylist-table table {
    width: 100%;
    font-size: 12px;
    margin-top: 10px;
  }

  .mylist-table th,
  .mylist-table td {
    padding: 5px;
  }

  .mylist-input {
    width: 100%;
    margin-left: 0;
  }

  .mylist-optimise-section {
    margin-top: 2px;
    padding: 10px; /* Add padding for spacing on all sides */
    box-sizing: border-box; /* Include padding in the element's dimensions */
  }

  .mylist-optimise-heading {
    font-size: 14px;
  }

  .mylist-optimise-content {
    font-size: 12px;
  }

  .mylist-next-button {
    padding: 10px 20px;
    width: 100%;
  }

  .mylist-submit-button-container {
    justify-content: center;
    margin-top: 10px;
  }

  .mylist-submit-button {
    padding: 10px 20px;
  }

  .mylist-card-content {
    padding: 20px; /* Add padding for spacing inside the card */
    margin: 20px; /* Add margin to create space on all sides of the card */
    max-width: none;
    width: calc(100% - 40px); /* Full width minus the total margin */
    box-sizing: border-box; /* Include padding and margin in width calculation */
  }

  .mylist-input-container {
    margin-left: 0;
    margin-right: 15px;
    padding: 5px;
  }

  .mylist-input-row {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0;
  }

  .mylist-label {
    text-align: left;
  }

  .mylist-notes-container {
    margin-top: 5px;
  }

  .mylist-notes-label {
    margin-left: 0;
    margin-right: 10px;
    margin-top: 5px;
  }

  .mylist-warning-message {
    margin-top: -15px;
    margin-left: 0;
  }
}


/* Media Query for 320px to 1020px screen widths */
@media (max-width: 1020px) {
  .mylist-advanced-title {
    font-size: 12px;
    margin-right: 2px;
    margin-bottom: 10px;
    margin-top: -10px;
  }

  .mylist-gearbox-icon {
    width: 30px;
    height: 30px;
    margin-right: 60px;
    margin-bottom: 10px;
    margin-top: -10px;
  }

  .mylist-advanced-content {
    padding: 10px;
  }

  .mylist-label-left,
  .mylist-percentage-right {
    font-size: 12px;
  }

  .mylist-save-button {
    padding: 8px 20px;
    font-size: 12px;
  }
}
