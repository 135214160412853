.momentumdiscovery-container-fluid {
  width: 95%;
  min-width: 420px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  margin-top: 3rem;
}

.momentumdiscovery-text-center {
  text-align: center;
  margin-bottom: 1.5rem;
}

.momentumdiscovery-display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.momentumdiscovery-mb-3-text-center {
  margin-bottom: 2rem; /* Add spacing below dropdown */
  /* text-align: center; */
  display: flex; /* Flex layout for alignment */
  align-items: center; /* Center align vertically */
  justify-content: flex-start; /* Align dropdown to the left */
}

.momentumdiscovery-form-label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
}

.momentumdiscovery-form-select {
  display: block;
  width: 25%; /* Adjust width as needed */
  padding: 0.5rem 1rem; /* Adjust padding */
  font-size: 1.2rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
  margin: 0;
  appearance: none; /* Remove default arrow */
  position: relative;
  background-image: url('../../assets/down-arrow.png'); /* Update with the correct path */
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) center; /* Adjust arrow position */
  background-size: 12px 12px; /* Adjust size of the arrow */
}




.momentumdiscovery-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.momentumdiscovery-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center-align the cards */
  margin-right: -1rem;
  margin-left: -1rem;
}

.momentumdiscovery-g-3 > * {
  padding-right: 1rem;
  padding-left: 1rem;
  margin-bottom: 2rem; /* Added gap between graphs */
}

.momentumdiscovery-mt-4 {
  margin-top: 1.5rem;
}

.momentumdiscovery-col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  justify-content: center; /* Center-align the card */
}

.momentumdiscovery-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  padding: 1.5rem;
  height: 500px;
  width: 75%;
  margin-left: 0; /* Align graph card to the left */
}