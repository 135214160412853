/* Profile.css */
.profile-page {
  background-color: #efefef;
  color: #ffffff;
  min-height: 100vh;
}

.profile-container {
  padding: 80px;
  margin-top: -70px;
}

.profile-title {
  font-size: 28px;
  margin-bottom: 20px;
  justify-content: center;
  display: flex;
  color: #ff8300;
}

.profile-tab-list {
  display: grid;
  gap: 10px;
  margin-bottom: 20px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(150px, 1fr)
  ); /* Adjust the min width as needed */
  justify-content: center;
  margin-left: -30px;
  font-size: 14px;
}

.profile-tab {
  padding: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #000000;
  font-weight: bold;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  justify-content: center; /* Centering the content */
  text-align: center;
}

.profile-tab img {
  margin-right: 8px;
  width: 30px;
  height: 30px;
}

.react-tabs__tab--selected {
  background-color: hwb(0 20% 80%) !important;
  color: white !important;
}

.profile-info,
.subscription-info,
.notification-info,
.help-info {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  border-radius: 8px;
  margin-top: 20px;
}

.profile-info-item,
.subscription-info-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.profile-info-label,
.subscription-info-label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #000000;
}

.profile-info-input,
.subscription-info-input {
  padding: 15px 10px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(243, 242, 242);
  background-color: rgb(255, 255, 255);
  color: #000000;
}

.notification-title,
.help-title {
  font-size: 20px;
  margin-bottom: 10px;
  color: #000000;
}

.help-info {
  font-size: 16px;
}

.notification-info {
  color: #000000;
  font-size: 16px;
}

.help-paragraph {
  color: #000000;
  font-size: 16px;
}

.help-paragraph .email-highlight {
  color: #ff8300;
  font-weight: bold;
}

.subscription-info-input.active {
  color: #0cde0c;
  font-weight: bold;
}

.subscription-info-input.inactive {
  color: red;
  font-weight: bold;
}

.notification-info {
  max-height: 300px; /* Adjust height as needed */
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
}

.notification-card {
  display: flex;
  flex-direction: column;
}

.notification-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-message {
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
}

.notification-time {
  color: gray;
  margin-right: 10px;
}

.delete-button {
  background-color: #ff8300;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #e56f00;
}

.notification-title {
  font-size: 20px;
  margin-bottom: 10px;
  color: #000000;
}

.no-notification {
  color: #000000;
  font-size: 16px;
}

.loader-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .profile-container {
    padding: 0px 20px;
    margin-top: 0;
  }

  .profile-tab-list {
    flex-wrap: wrap;
    justify-content: center;
    font-size: 12px;
  }

  .profile-tab {
    flex: 1 1 45%;
    margin: 10px;
    padding: 10px 20px;
    justify-content: center;
    text-align: center;
  }

  .profile-tab img {
    width: 20px;
  }

  .profile-info,
  .subscription-info,
  .notification-info,
  .help-info {
    margin-top: 10px;
    padding: 20px;
  }

  .profile-info-item,
  .subscription-info-item {
    margin-bottom: 10px;
  }

  .profile-info-input,
  .subscription-info-input {
    padding: 10px 8px;
  }

  .notification-info,
  .help-info {
    font-size: 12px;
  }

  .delete-button {
    padding: 5px 8px;
  }
}
