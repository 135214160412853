.Analyser-App {
  font-family: Arial, sans-serif;
  background-color: #efefef;
  min-height: 100vh;
}

.trial-edition-container {
  margin: 10px;
  font-weight: bold;
  font-size: 20px;
}

.trial-edition {
  color: #4071ec; /* Blue color for "Trial Edition" */
}


.subscribe-link {
  color: #fc2400; /* Orange color for the link */
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
}

.subscribe-link:hover {
  text-decoration: underline; /* Underline on hover */
}

.contact-message {
  text-align: center;
  font-size: 14px;
  color: #000; /* Adjust the color as needed */
  opacity: 0.5;
  margin-top: 10px;
}

.contact-email {
  text-decoration: none; /* Removes the underline */
  color: inherit; /* Keeps the text color the same as the surrounding text */
}

.contact-email:hover {
  text-decoration: underline; /* Optionally, add an underline on hover */
}

.Analyser-content {
  display: flex;
  padding: 20px;
}

.refresh-icon {
  position: absolute;
  margin-top: 7px;
  right: 30px;
  width: 20px;
  cursor: pointer;
  color: #fc2400;
}

.floating-arrow {
  position: fixed;
  right: 15px;
  bottom: 40px;
  background: transparent;
  border: 2px solid rgb(152, 150, 150);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1000;
}

.floating-arrow img {
  width: 15px;
  height: 15px;
  transition: transform 0.3s;
}

.arrow-up {
  transform: rotate(180deg);
}

.arrow-down {
  transform: rotate(0deg);
}

/* Media Query for 320px to 1020px screen widths */
@media (max-width: 1020px) {
  .Content-wrapper {
    padding: 10px; /* Add padding for smaller screens excluding the navbar */
  }

  .Analyser-content {
    flex-direction: column; /* Stack elements vertically */
    padding: 10px; /* Adjust padding for smaller screens */
    height: 100vh; /* Use full viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */
    box-sizing: border-box; /* Include padding in height calculation */
  }

  .stocks,
  .mylist {
    width: 100%; /* Full width for smaller screens */
    margin-right: 0; /* Remove right margin */
  }

  .stocks-header,
  .mylist-header {
    display: flex; /* Use Flexbox for horizontal layout */
    justify-content: space-between; /* Space between items */
    align-items: center; /* Center align items vertically */
    flex-direction: row; /* Keep elements in a row */
    width: 100%;
  }

  .stocks-header h2 {
    font-size: 16px; /* Adjust font size */
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .mylist-header h2 {
    font-size: 16px; /* Adjust font size */
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .funnel-icon-container {
    margin: 0;
    margin-right: 0px;
    margin-bottom: 20px;
    align-self: flex-end; /* Align icon to the right */
    cursor: pointer; /* Add pointer cursor */
  }

  .mylist-refresh-icon {
    margin: 0;
    margin-right: 5px;
    margin-bottom: 10px;
    align-self: flex-end; /* Align icon to the right */
    cursor: pointer; /* Add pointer cursor */
  }

  .stocks-table {
    max-height: 300px; /* Limit max-height for better visibility */
    overflow-x: auto; /* Allow horizontal scrolling if needed */
    overflow-y: auto; /* Enable vertical scrolling */
  }

  .mylist-table {
    max-height: 200px; /* Limit max-height for better visibility */
    overflow-x: auto; /* Allow horizontal scrolling if needed */
    overflow-y: auto; /* Enable vertical scrolling */
  }

  .stocks-table table,
  .mylist-table table {
    width: 100%; /* Ensure table takes full width */
    font-size: 12px; /* Adjust font size */
  }

  .stocks-card,
  .mylist-card {
    width: 100%; /* Full width for cards */
    margin: 0; /* Remove margin */
  }

  .mylist-optimise-section {
    width: 100%; /* Full width for buttons and sections */
    margin-bottom: 100px;
  }
  .mylist-next-button,
  .mylist-submit-button {
    width: 100%; /* Full width for buttons and sections */
  }

  .mylist-card-content {
    width: 100%; /* Ensure full width for card content */
    box-sizing: border-box; /* Include padding in width */
    padding: 20px; /* Adjust padding */
  }

  .mylist-input-container,
  .mylist-notes-container {
    margin-left: 0; /* Remove margin */
    padding: 0; /* Remove padding */
  }

  .mylist-input-row {
    flex-direction: column; /* Stack input rows */
    align-items: flex-start; /* Align items to start */
  }

  .mylist-input {
    width: 100%; /* Full width for input fields */
  }

  .mylist-label {
    margin-bottom: 5px; /* Add space below labels */
  }

  .mylist-notes {
    text-align: left; /* Left-align notes */
    margin-top: 0; /* Remove top margin */
  }

  .mylist-submit-button-container {
    justify-content: center; /* Center align the submit button */
  }

  .mylist-optimise-section {
    display: block; /* Ensure it is visible */
    overflow: visible; /* Ensure content is not cut off */
  }
}
