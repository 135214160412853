/* ThankYou.css */
.thank-you-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
}

.thank-you-content {
    text-align: center;
}

.thank-you-animation {
    width: 80px;
    margin-bottom: 10px;
}

.thank-you-button {
    background-color: #fc2400;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}

.thank-you-button:hover {
    background-color: #d41a00;
}
