/* Main container to center the card */
.qr-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;
}

/* Card container */
.qr-card {
    display: flex;
    flex-direction: row; /* Layout side by side for desktop */
    width: 100%;
    max-width: 800px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 20px;
    gap: 20px; /* Add some spacing between sections */
}

/* Section for the QR code and download button */
.qr-code-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1;
}

/* QR image styling */
.qr-image {
    width: 200px;
    margin-bottom: 10px;
}

/* Download button styling */
.qr-download-btn {
    background-color: #ff6600;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.qr-download-btn:hover {
    background-color: #e65c00;
}

/* Payment details section */
.qr-payment-details {
    text-align: left;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Header in the payment details section */
.qr-header {
    color: red;
    font-size: 16px;
    margin-bottom: 10px;

}

/* Paragraph styling */
.note-p {
    margin-bottom: 10px;
    font-size: 14px;
    margin-top: -1px;
}

.transactionid-p {
    font-size: 14px;
    font-weight: 600;
    color: #e65c00;
}

/* Form input field */
.qr-input {
    width: calc(100% - 110px); /* Adjust to account for the button */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

/* Submit button next to input field */
.qr-submit-btn {
    background-color: #ff6600;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.qr-submit-btn:hover {
    background-color: #e65c00;
}

/* Form container */
.qr-form {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
}

/* Links styling */
.qr-payment-details a {
    color: #007bff;
    text-decoration: none;
}

.qr-payment-details a:hover {
    text-decoration: underline;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .qr-card {
        flex-direction: column; /* Stack items for mobile view */
        padding: 15px;
    }

    .qr-form {
        flex-direction: column; /* Stack the input and button vertically */
        align-items: stretch;
    }

    .qr-input {
        width: 95%; /* Make input take full width on mobile */
        padding: 10px;
        margin-bottom: 10px;
    }

    .qr-submit-btn {
        width: 100%; /* Make button take full width on mobile */
        padding: 10px;
    }
}
