/* General Modal Styles */
.subscribe-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.subscribe-modal-content {
  background: white;
  padding: 15px;
  border-radius: 8px;
  text-align: left;
  max-width: 600px;
  width: 100%;
  position: relative;
  z-index: 1001;
}

.subscribe-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fc2400;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 26px;
  cursor: pointer;
}

.subscribe-title {
  color: #fc2400;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 5px;
}

.subscribe-input-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 100px;
}

.subscribe-label {
  flex: 1;
  text-align: left;
}

.subscribe-input {
  flex: 2;
  padding: 10px;
  width: 80px;
  border: 1px solid #000000;
  border-radius: 5px;
}

.subscribe-input::-webkit-outer-spin-button,
.subscribe-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.subscribe-input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
  -webkit-appearance: none;   /* For Chrome, Safari, and newer versions of Opera */
  appearance: none;           /* Standard property */
}

.subscribe-activate-button {
  width: 100px; /* Ensure a fixed width */
  padding: 10px 20px;
  background-color: #fc2400; /* Your desired background color */
  color: #ffffff; /* Text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscribe-activate-button:disabled {
  opacity: 0.7; /* Optional: dim the button when disabled */
  cursor: not-allowed;
}

.subscribe-or-container {
  display: flex;
  justify-content: center;
  margin: -10px;
}

.subscribe-or-note {
  font-size: 20px;
  font-weight: bold;
}

.subscribe-submit-button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.subscribe-submit-button {
  background-color: #fc2400;
  color: white;
  padding: 10px 150px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.subscribe-submit-button:hover {
  background-color: #d41a00;
}

/* Media Query for Mobile View */
@media (max-width: 768px) {
  .subscribe-modal-content {
      max-width: 90%; /* Decrease modal width for mobile screens */
      padding: 10px;
  }

  .subscribe-title {
      font-size: 18px; /* Smaller title font on mobile */
      margin-bottom: 10px;
  }

  .subscribe-input-row {
      flex-direction: column; /* Stack input label and input field vertically */
      margin-right: 0; /* Remove margin to make it fit better on small screens */
  }

  .subscribe-label {
      text-align: left;
      margin-bottom: 10px; /* Add some space between label and input */
      margin-top: 10px;
  }

  .subscribe-input {
      width: 70%; /* Make the input take the full width on mobile */
      padding: 8px; /* Adjust padding for smaller screens */
  }

.subscribe-activate-button {
  width: 100px; /* Ensure a fixed width */
  padding: 10px 20px;
  background-color: #fc2400; /* Your desired background color */
  color: #ffffff; /* Text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscribe-activate-button:disabled {
  opacity: 0.7; /* Optional: dim the button when disabled */
  cursor: not-allowed;
}

  .subscribe-or-note {
      font-size: 16px; /* Reduce font size for the "OR" note */
  }

  .subscribe-submit-button {
      padding: 10px 60px; /* Adjust padding for the submit button */
  }

  .subscribe-close-button {
      width: 24px; /* Adjust size of the close button */
      height: 24px;
      font-size: 20px;
  }
}
