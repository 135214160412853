/* General Page Styling */
.stresstest-page-container {
  background-color: #efefef;
  padding: 40px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Development Message Styling */
.development-message {
  font-size: 32px;
  font-weight: bold;
  color: #fc2400;
  text-align: center;
  margin-top: -350px;
}

/* Header Styling */
.stresstest-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stresstest-title {
  font-size: 18px;
  color: #fc2400;
  font-weight: bold;
  text-align: center;
}

/* Content Container Styling */
.stresstest-content-container {
  display: flex;
  justify-content: center;
}

.stresstest-payment-section {
  width: 80%;
  margin-right: 20px; /* Add space between the two cards */
  position: relative;
}

.stresstest-card {
  background: #fff;
  border-radius: 8px;
}

.stresstest-payment-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent;
}

.stresstest-payment-card h2 {
  font-size: 18px;
  color: #fc2400;
  font-weight: bold;
  margin-bottom: 20px;
}

.stresstest-payment-card .stresstest-price-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}

.stresstest-payment-card .stresstest-price-row span {
  margin-right: 10px;
}

.stresstest-payment-card .stresstest-total {
  font-size: 18px;
  color: #fc2400;
  font-weight: bold;
}

.stresstest-payment-card .stresstest-email-note {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  margin-top: 10px;
}

.stresstest-upload-card {
  width: 25%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-right: 50px;
  max-height: 500px;
  margin-top: 20px;
}

.stresstest-upload-title {
  font-size: 18px;
  color: #1e3a8a; /* Dark blue color */
  font-weight: bold;
  margin-bottom: 5px;
}

.stresstest-upload-subtitle {
  font-size: 14px;
  color: #1e3a8a; /* Dark blue color */
  margin-bottom: 20px;
}

.stresstest-upload-box {
  border: 2px dashed #ddd;
  padding: 20px;
  text-align: center;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 10px;
}

.stresstest-upload-icon {
  width: 50px;
  margin-bottom: 10px;
}

.stresstest-upload-text {
  font-size: 14px;
  color: #1e3a8a; /* Dark blue color */
  margin-bottom: 5px;
}

.stresstest-upload-or {
  font-size: 14px;
  color: #1e3a8a; /* Dark blue color */
  margin-bottom: 10px;
}

.stresstest-button-container {
  position: absolute;
  top: 180px; /* Adjust based on the height of the buttons */
  right: 60px;
  display: flex;
  flex-direction: column;
}

.stresstest-btn {
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
}

.stresstest-browse-btn {
  background-color: #ff7f50;
}

.stresstest-upload-btn {
  background-color: #ff7f50;
  width: 80%;
}

.stresstest-text {
  font-size: 14px;
}

.stresstest-image {
  width: 75%;
  object-fit: fill;
  display: flex;
  align-self: center;
}
