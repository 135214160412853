/* Sidebar Styling */
.sidebar-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
}

.sidebar-open {
  transform: translateX(0);
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.sidebar-menu-icon {
  cursor: pointer;
  margin-top: 15px;
  margin-right: 5px;
  width: 25px;
  margin-left: auto;
}

.sidebar-logo {
  height: 30px;
}

.sidebar-ants-logo {
  margin-top: 15px;
  margin-left: 8px;
  height: 35px;
  width: auto;
}

.sidebar-menu {
  flex-grow: 1;
  overflow-y: auto; /* Enable vertical scrolling if needed */
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  text-decoration: none;
  color: black;
  font-size: 16px;
  transition: background-color 0.3s;
}

.sidebar-item img {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  filter: invert(44%) sepia(65%) saturate(6453%) hue-rotate(352deg)
    brightness(102%) contrast(103%);
}

.sidebar-item:hover {
  background-color: #f9f9f9;
}

.sidebar-item.active {
  background-color: #ff8300;
  color: white;
}

.sidebar-item.active img {
  filter: invert(100%);
}

.sidebar-footer {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebar-footer .sidebar-item {
  padding: 10px 15px;
  border: 2px solid #ff8300;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 14px;
}

.sidebar-footer .sidebar-item:hover {
  background-color: #ff8300;
  color: #ffffff;
}

/* Media Query for 320px to 1020px screen widths */
@media (max-width: 1020px) {
  .stocks-table-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }

  .stocks-table {
    width: 100%; /* Full width for container */
    overflow-x: auto; /* Enable horizontal scrolling for the table */
  }

  .stocks-table table {
    width: 600px; /* Set a fixed width for the table */
    min-width: 100%; /* Ensure table is at least as wide as the container */
    overflow-x: auto; /* Enable horizontal scrolling for the table */
    font-size: 12px; /* Adjust font size */
  }

  .stocks-table th,
  .stocks-table td {
    white-space: nowrap; /* Prevent table cells from wrapping */
  }

  .stocks-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .funnel-icon-container {
    align-self: flex-end;
    margin-right: 0; /* Remove right margin */
  }
}
