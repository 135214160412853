.stocks {
  flex: 3;
  margin-right: 20px;
  font-size: 12px;
}

.stocks-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* This moves the funnel icon to the right */
  font-size: 12px;
}

.stocks-h2 {
  color: #fc2400;
  font-size: 14px; /* Updated font size */
  margin-left: 0px; /* Space between heading and icon */
}

.funnel-icon {
  width: 14px;
  font-size: 12px;
}

.funnel-icon-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 30px;
  font-size: 12px; /* Updated font size */
}

.funnel-icon-container .tooltip {
  visibility: hidden;
  width: 80px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the icon */
  left: 50%;
  margin-left: -40px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px; /* Updated font size */
}

.funnel-icon-container .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.funnel-icon-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.stocks-table-container {
  max-height: 450px; /* Adjust the height as needed */
  overflow-y: auto;
  margin-bottom: 0; /* Space between table and button */
}

.stocks-table {
  background-color: white;
  padding: 35px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 12px; /* Updated font size */
}

.stocks-table table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px; /* Updated font size */
}

.stocks-table th {
  padding: 10px;
  border-bottom: 1.5px solid hsl(0, 0%, 49%);
  text-align: left;
  font-size: 12px; /* Updated font size */
}

.stocks-table td {
  padding: 10px;
  margin-left: 10px;
  font-size: 12px; /* Updated font size */
}

.stocks-table th.hide-header {
  display: none;
  font-size: 12px;
}

.stocks-table .right-align {
  text-align: right; /* Align text to the right */
  font-size: 12px; /* Updated font size */
}

.action-arrow {
  width: 20px;
  cursor: pointer;
  font-size: 12px; /* Updated font size */
}

.dropdown-cell {
  position: relative;
  font-size: 12px; /* Updated font size */
}

.custom-dropdown {
  position: relative;
  font-size: 12px; /* Updated font size */
}

.custom-dropdown input {
  appearance: none;
  width: 90%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
  font-size: 12px; /* Updated font size */
  cursor: pointer;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  font-size: 12px; /* Updated font size */
}

.dropdown-option {
  padding: 10px;
  cursor: pointer;
  font-size: 12px; /* Updated font size */
}

.dropdown-option:hover {
  background-color: #f0f0f0;
  font-size: 12px;
}

.dropdown-cell .dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 15px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 12px;
}

/* Style for disabled dropdowns */
.dropdown-cell .disabled-dropdown {
  background-color: #f0f0f0; /* Light gray background */
  color: #a0a0a0; /* Gray text */
  cursor: not-allowed; /* Not-allowed cursor */
  pointer-events: none; /* Disable interaction */
  border: 1px solid #dcdcdc; /* Light border */
}

.dropdown-cell .disabled-dropdown option {
  color: #a0a0a0; /* Gray color for dropdown options */
}

.push-button-container {
  display: flex;
  justify-content: flex-end;
  background-color: white;
  padding: 5px 3px 5px 20px;
  border-top: 1px solid #ddd;
  border-radius: 0 0 8px 8px;
  font-size: 14px; /* Updated font size */

  margin-right: 7px;
}

.push-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fc2400;
  cursor: pointer;
  font-size: 14 px; /* Updated font size */
}

.push-button img {
  margin-left: 5px;
  width: 25px;
  font-size: 12px;
}

/* Custom scrollbar styling */
.stocks-table-container::-webkit-scrollbar {
  width: 6px;
}

.stocks-table-container::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 10px;
}

.dropdown-options::-webkit-scrollbar {
  width: 8px;
}

.dropdown-options::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 10px;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-size: 12px; /* Updated font size */
}

.modal-content {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  width: 80%;
  position: relative;
  margin-bottom: 300px;
  font-size: 12px; /* Updated font size */
}

.stocks-h3 {
  color: #fc2400;
  font-size: 12px; /* Updated font size */
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -40px;
  font-size: 12px; /* Updated font size */
}

.stocks-close-icon {
  width: 40px;
  cursor: pointer;
  margin-right: -30px;
  display: flex;
  margin-top: -30px;
  font-size: 12px;
}

.modal-body {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  font-size: 12px; /* Updated font size */
}

.modal-section {
  width: 70%;
  font-size: 12px; /* Updated font size */
}

.stocks-card {
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  font-size: 12px; /* Updated font size */
}

.stocks-radio-group-row {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  font-size: 12px; /* Updated font size */
}

.stocks-radio-group-row label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px; /* Updated font size */
}

.stocks-radio-group input[type="radio"] {
  appearance: none;
  width: 30px;
  height: 30px;
  border: 2px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  font-size: 12px;
}

.stocks-radio-group input[type="radio"]:checked {
  border-color: #fc2400;
  background-color: #fc2400;
  font-size: 12px;
}

.apply-button {
  background-color: #fc2400;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 12px; /* Updated font size */
  font-weight: bold;
  text-align: center;
}

.apply-button:hover {
  background-color: #d41a00;
  font-size: 12px;
}

.apply-button-container {
  display: flex;
  justify-content: flex-end;
  font-size: 12px; /* Updated font size */
}

/* Style for the message row */
.only-pro-premium-row {
  height: 50px; /* Add some height to create spacing between rows */
}

.only-pro-premium-text {
  text-align: center;
  font-weight: bold;
  color: #fc2400;
  font-size: 1.1rem; /* Slightly larger font size */
  padding: 15px 0; /* Add padding for better spacing */
}

/* Add space between rows */
.stocks-table tr {
  margin-bottom: 20px; /* Space between rows */
}


/* Media Query for 320px to 1020px screen widths */
@media (max-width: 1020px) {
  .modal-content {
    width: 80%; /* Ensure the modal content is not too wide */
    max-width: 600px; /* Set a maximum width for the modal content */
    margin: 10px; /* Center the modal content horizontally */
    padding: 0px; /* Adjust padding */
    text-align: center; /* Center text inside the modal */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Add shadow for main card */
    border-radius: 8px; /* Rounded corners for main card */
    background-color: #fff; /* Background color for main card */
  }

  .modal-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0px; /* Space between header and content */
  }

  .stocks-close-icon {
    width: 30px; /* Adjust size for better visibility */
    cursor: pointer;
    margin-top: 5px;
    margin-right: 5px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    gap: 0px; /* Space between the cards and button */
  }

  .modal-section {
    width: 80%; /* Full width for each section */
    max-width: 600px; /* Limit the width of each card */
    padding: 10px; /* 20px padding inside each section */
    background-color: #f9f9f9; /* Light background for nested cards */
    border-radius: 8px; /* Rounded corners for nested cards */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for nested cards */
    margin: 8px; /* Margin between cards */
    margin-top: 10px;
  }

  .stocks-h3 {
    display: flex;
    align-items: flex-start; /* Align items to the start */
    margin-bottom: 10px; /* Space below headings */
    font-size: 12.5px; /* Adjust font size for better readability */
  }

  .stocks-card {
    padding: 0px; /* Add padding inside the card */
    margin-bottom: 10px; /* Space below each card */
    text-align: left; /* Left-align text inside the cards */
  }

  .stocks-radio-group-row {
    display: flex;
    flex-direction: column; /* Stack radio options vertically */
    align-items: flex-start; /* Align items to the start */
    gap: 10px; /* Space between radio options */
    background-color: #efefef; /* Ensure transparency */
  }

  .apply-button-container {
    display: flex;
    justify-content: center; /* Center the apply button */
    margin-bottom: 10px;
  }

  .apply-button {
    padding: 10px 20px; /* Adjust padding */
    font-size: 12.5px; /* Adjust font size */
    background-color: #fc2400; /* Button background color */
    color: white; /* Button text color */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    width: auto; /* Button width adjusts to content */
  }

  .apply-button:hover {
    background-color: #d41a00; /* Darker shade on hover */
  }

  .stocks-table-container {
    max-height: 400px; /* Limit max-height for scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Enable horizontal scrolling */
  }

  .stocks-table {
    width: 100%; /* Full width for the table */
    padding: 5px; /* Adjust padding for smaller screens */
    font-size: 12px; /* Adjust font size for table content */
    border-collapse: collapse; /* Collapse borders */
  }

  .stocks-table th,
  .stocks-table td {
    padding: 10px; /* Padding for table cells */
    font-size: 12px; /* Adjust font size */
  }

  .dropdown-cell {
    position: relative;
    min-width: 120px; /* Minimum width for dropdown cell */
    font-size: 10px;
  }

  .dropdown-arrow {
    position: absolute;
    margin-right: -10px;
  }

  .dropdown-options {
    width: 130px; /* Ensure dropdown options take full width */
    max-width: 400px; /* Set a max width for the dropdown options */
  }

  .push-button-container {
    display: flex; /* Set display to flex */
    margin-right: -2.5px;
  }

  .action-arrow {
    transform: rotate(90deg); /* Rotate the action arrow 60 degrees */
  }
}
