/* General styling */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.signup-container {
  display: flex;
  background: linear-gradient(to bottom, #fea239, #ff8376);
  height: 100vh;
}

.signup-left {
  flex: 3.1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.signup-leftlogo1 {
  height: 100vh; /* Adjust height to fit the viewport */
}

.signup-right {
  flex: 1;
  background: linear-gradient(to bottom, #ffffff, #ffffff, #ffe5c7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px;
  margin-right: 90px;
}

.signup-form {
  width: 100%;
  max-width: 400px;
  left: -10px;
  text-align: center;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
  scrollbar-width: none; /* Hide scrollbar on Firefox */
}

.signup-form::-webkit-scrollbar {
  display: none; /* Hide scrollbar on Chrome, Safari, and Edge */
}

.signup-logo {
  width: 175px;
  height: 60px;
  margin-bottom: 40px;
}

.signup-h2 {
  font-size: 20px;
  margin-left: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.signup-input-group1 {
  margin-bottom: 20px;
  text-align: left;
  margin-top: 20px; /* Adjusted for better spacing */
}

.signup-input-group {
  margin-bottom: 20px;
  text-align: left;
  margin-top: 40px;
}

.signup-input-group1 label,
.signup-input-group label {
  display: block;
  margin-bottom: 8px;
  color: #494646;
  font-size: 14px;
  font-weight: bold;
}

.signup-input-group1 .input1,
.signup-input-group input {
  width: 320px;
  height: 12px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #e7dfdf;
  border-radius: 5px;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.1);
}

.signup-forgot-password {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
}

.signup-forgot-password a {
  color: #fea239;
  text-decoration: none;
}

.signup-submit-button,
.signup-button {
  width: 50%;
  height: 50px;
  padding: 13px;
  border-color: #c9574b;
  background: linear-gradient(to left, #ff8376, #fea239);
  color: white;
  font-size: 1.4rem;
  cursor: pointer;
  margin-top: 30px; /* Adjusted for better spacing */
  box-shadow: 0px 3px 10px #ff837669;
  border: 1px solid #c9574b;
  border-radius: 15px;
  opacity: 1;
}

.signup-submit-button:hover,
.signup-button:hover {
  background: linear-gradient(to left, #ff8376, #fea239);
}

.signup-submit-button:disabled {
  cursor: not-allowed;
  background-color: #ccc; /* Change the background color to indicate it's disabled */
}

.signup-otp-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.signup-otp-modal-content {
  position: relative;
  background: white;
  padding: 30px;
  border-radius: 20px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.signup-otp-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fc2400;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-otp-close-button:hover {
  background-color: #d11f00;
}

.signup-otp-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 25px;
}

.signup-otp-inputs input {
  width: 60px;
  height: 60px;
  font-size: 24px;
  text-align: center;
  border: 1px solid #e7dfdf;
  border-radius: 10px;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.1);
}

.signup-resend-otp {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-left: 30px;
  padding: 10px;
}

.signup-resend-otp .resend-link {
  cursor: pointer;
  color: #fea239;
}

.signup-resend-otp .disabled {
  cursor: not-allowed;
  color: grey;
}

.signup-checkbox-container {
  display: flex;
  justify-content: start;
  margin-top: 20px;
  cursor: pointer;
  font-size: 12px;
}

.signup-verify-checkbox {
  position: relative;
  width: 15px;
  height: 15px;
}

.signup-verify-checkbox-label {
  position: relative;
  margin-top: 3px;
  margin-left: 2px;
}

.signup-mandatory {
  color: red;
  font-weight: bold;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .signup-container {
    flex-direction: column;
  }

  .signup-left {
    display: none;
  }

  .signup-right {
    flex: none;
    width: 100%;
    padding: 20px;
    margin: 0;
    height: 100vh; /* Adjust height to fit the viewport */
  }

  .signup-right img {
    width: 150px;
    height: 70px;
  }

  .signup-form {
    max-width: 100%;
    padding: 0 20px; /* Add padding for better spacing */
  }

  .signup-input-group input {
    width: 100%;
  }

  .signup-submit-button,
  .signup-button {
    width: 100%;
  }
}

@media screen and (max-width: 1105px) and (min-width: 320px) {
  .signup-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height of the viewport */
    overflow: hidden; /* Prevent scrolling */
  }

  .signup-left {
    display: none;
  }

  .signup-right img {
    width: 140px;
    height: 50px;
  }

  .signup-right {
    flex: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 0;
  }

  .signup-logo {
    width: 175px;
    height: 60px;
    margin-bottom: 40px;
  }

  .signup-form {
    width: 100%;
    max-width: 400px;
    text-align: center;
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 0 20px; /* Padding for better spacing */
    margin-bottom: 250px;
  }

  .signup-otp-modal-content {
    max-width: 280px;
    max-height: 250px; /* Adjust the max height as needed */
    height: 90%; /* Set the height to 90% of the screen */
  }

  .signup-otp-inputs {
    margin-bottom: 20px;
    margin-left: 25px;
    gap: 15px;
    margin-right: 30px;
  }

  .signup-otp-inputs input {
    width: 55px;
    height: 55px;
    font-size: 20px;
    text-align: center;
    border: 1px solid #e7dfdf;
    border-radius: 10px;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .signup-resend-otp {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 10px;
  }

  .signup-resend-otp .resend-link {
    cursor: pointer;
    color: #fea239;
    margin-left: -55px;
  }

  .signup-input-group,
  .signup-input-group1 {
    margin-top: 20px;
    text-align: center;
  }

  .signup-input-group label,
  .signup-input-group1 label {
    margin-left: 16%;
    text-align: left;
    width: 60%;
    display: block;
  }

  .signup-input-group input,
  .signup-input-group1 .input1 {
    width: 70%;
    font-size: 1rem;
    margin-left: 6%;
  }

  .signup-submit-button,
  .signup-button {
    width: 50%;
    margin-top: 20px;
  }

  .signup-forgot-password {
    text-align: center;
    margin-top: 10px;
    font-size: 0.9rem;
  }

  .signup-otp-modal {
    justify-content: center;
    align-items: center;
  }

  .signup-otp-inputs {
    justify-content: center;
    margin-left: 0;
  }

  .signup-verify-checkbox {
    margin-right: -40px;
  }
  .signup-checkbox-container {
    margin-left: 15%;
  }
  .signup-verify-checkbox-label {
    font-size: 10px;
  }
}
