/* CancellationRefundPolicy.css */

.policy-container {
    padding: 20px;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    background-color: #ffffff;
    overflow-y: auto;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #007bff #f1f1f1; /* For Firefox */
  }
  
  .policy-container::-webkit-scrollbar {
    width: 12px;
  }
  
  .policy-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  .policy-container::-webkit-scrollbar-thumb {
    background-color: #007bff;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
    cursor: pointer;
  }
  
  .policy-container::-webkit-scrollbar-thumb:hover {
    background-color: #0056b3;
  }
  
  .policy-logo {
    display: block;
    margin: 0 auto 20px;
    max-width: 150px;
    height: auto;
  }
  
  .policy-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .policy-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.8;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .policy-list {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .policy-item {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
    text-align: justify;
  }
  