/* PrivacyPolicy.css */

/* Apply custom scrollbar styles only within the .policy-container */
.policy-container {
    padding: 20px;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    background-color: #ffffff;
    /* No overflow control needed; it uses the main scrollbar */
  }
  
  /* Scope the scrollbar styling to the .policy-container */
  .policy-container {
    overflow-y: auto; /* Allow scrolling within this container if needed */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #007bff #f1f1f1; /* For Firefox */
  }
  
  .policy-container::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }
  
  .policy-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
    border-radius: 10px; /* Rounded corners for the track */
  }
  
  .policy-container::-webkit-scrollbar-thumb {
    background-color: #007bff; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
    border: 3px solid #f1f1f1; /* Padding around the thumb */
    cursor: pointer; /* Change cursor to pointer when hovering over the thumb */
  }
  
  .policy-container::-webkit-scrollbar-thumb:hover {
    background-color: #0056b3; /* Darker color on hover */
  }
  
  /* Other styles for the content inside .policy-container */
  .policy-logo {
    display: block;
    margin: 0 auto 20px;
    max-width: 150px;
    height: auto;
  }
  
  .policy-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .policy-update {
    font-size: 1rem;
    text-align: center;
    color: #666;
    margin-bottom: 20px;
  }
  
  .policy-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.8;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .policy-subheading {
    font-size: 1.7rem;
    margin-top: 40px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .policy-section {
    font-size: 1.2rem;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #444;
  }
  
  .policy-subsection {
    font-size: 1.1rem;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #555;
  }
  
  .policy-list {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .policy-item {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
    text-align: justify;
  }
  
  .policy-strong {
    font-weight: bold;
    color: #000;
  }
  
  .policy-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .policy-link:hover {
    text-decoration: underline;
  }
  