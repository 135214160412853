/* General styling */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.login-container {
  display: flex;
  background: linear-gradient(to bottom, #fea239, #ff8376);
  height: 100vh;
}

.login-left {
  flex: 3.1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.login-leftlogo1 {
  height: 100vh;
}

.login-right {
  flex: 1;
  background: linear-gradient(to bottom, #ffffff, #ffffff, #ffe5c7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 90px;
  margin-right: 90px;
}

.login-right img {
  width: 175px;
  height: 60px;
  margin-bottom: 40px;
}

.login-form {
  position: relative;
  width: 100%;
  max-width: 400px;
  text-align: center;
  left: -10px;
}

.login-h2 {
  font-size: 20px;
  margin-left: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.login-input-group {
  margin-bottom: 20px;
  text-align: left;
  margin-top: 40px;
}

.login-input-group label {
  display: block;
  margin-bottom: 8px;
  color: #494646;
  font-size: 14px;
  font-weight: bold;
}

.login-input-group input {
 
  width: 320px;
  height: 12px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #e7dfdf;
  border-radius: 5px;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.1);
}

.login-forgot-password {
  margin-bottom: 15px;
  text-align: left;
  font-size: 14px;
}

.login-forgot-password a {
  color: #fea239;
  text-decoration: none;
}

.login-signup-link {
  font-size: 14px;
}
.login-signup-link a {
  color: #fea239;
  text-decoration: none;
}

.login-button {
  width: 50%;
  height: 50px;
  padding: 12px;
  border-color: #c9574b;
  background: linear-gradient(to left, #ff8376, #fea239);
  color: white;
  font-size: 1.3rem;
  cursor: pointer;
  margin-top: 50px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 10px #ff837669;
  border: 1px solid #c9574b;
  border-radius: 15px;
  opacity: 1;
}

.login-button:hover {
  background: linear-gradient(to left, #ff8376, #fea239);
}

.login-error-message {
  color: red;
  margin-bottom: 20px;
}

.login-button[disabled] {
  cursor: not-allowed;
}

.login-password-field {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  
}

.login-password-field input {
  flex: 1;
  padding-right: 40px; /* Space for the icon */
  margin-left: -2%;
}

.login-toggle-password-visibility {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 2px;
}


/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .login-left {
    display: none;
  }

  .login-right {
    flex: none;
    width: 100%;
    padding: 20px;
    margin: 0;
  }

  .login-right img {
    width: 150px;
    height: 70px;
  }

  .login-form {
    max-width: 100%;
  }

  .login-input-group input {
    width: 100%;
  }

  .login-button {
    width: 100%;
  }
}

.login-checkbox-container {
  display: flex;
  justify-content: start;
  margin-top: 20px;
  cursor: pointer;
  font-size: 12px;
}

.login-verify-checkbox {
  position: relative;
  width: 15px;
  height: 15px;
}

.login-verify-checkbox-label {
  position: relative;
  margin-top: 3px;
  margin-left: 2px;
}

.login-button:disabled {
  cursor: not-allowed;
  background-color: #ccc; /* Change the background color to indicate it's disabled */
}

.login-mandatory {
  color: red;
  font-weight: bold;
}

.login-password-field {
  position: relative;
  display: flex;
  align-items: center;
  width: 89%;
  margin-left: 1.5%;
}

.login-password-field input {
  flex: 1;
  padding-right: 40px; /* Space for the icon */
}

.login-toggle-password-visibility {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 1105px) and (min-width: 320px) {
  .login-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height of the viewport */
    overflow: hidden; /* Prevent scrolling */
  }

  .login-left {
    display: none;
  }

  .login-right {
    flex: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 0;
  }

  .login-right img {
    width: 140px;
    height: 50px;
    margin-bottom: 20px;
  }

  .login-form {
    width: 100%;
    max-width: 400px;
    text-align: center;
    margin-bottom: 200px;
  }

  .login-input-group {
    margin-top: 20px;
  }

  .login-input-group label {
    margin-left: 16%;
    text-align: left;
    width: 60%;
    display: block;
  }

  .login-input-group input {
    width: 70%;
    font-size: 1rem;
    margin-left: 15%;
  }

  .login-button {
    width: 50%;
    margin-top: 20px;
  }

  .login-signup-link {
    margin-top: 10px;
    font-size: 0.9rem;
  }

  .login-forgot-password {
    text-align: start;
    margin-top: 20px;
    margin-left: 60px;
  }

  .login-checkbox-container {
    justify-content: start;
    margin-top: 20px;
    display: flex;
    align-items: center;
  }

  .login-checkbox-container input {
    margin-left: 60px;
  }
}