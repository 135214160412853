/* TermsConditions.css */

/* Apply custom scrollbar styles only within the .tnc-container */
.tnc-container {
    padding: 20px;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    background-color: #ffffff;
    overflow-y: auto; /* Allow scrolling within this container if needed */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #007bff #f1f1f1; /* For Firefox */
  }
  
  /* Scoped custom scrollbar styling for .tnc-container */
  .tnc-container::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }
  
  .tnc-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
    border-radius: 10px; /* Rounded corners for the track */
  }
  
  .tnc-container::-webkit-scrollbar-thumb {
    background-color: #007bff; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
    border: 3px solid #f1f1f1; /* Padding around the thumb */
    cursor: pointer; /* Change cursor to pointer when hovering over the thumb */
  }
  
  .tnc-container::-webkit-scrollbar-thumb:hover {
    background-color: #0056b3; /* Darker color on hover */
  }
  
  /* Other styles for the content inside .tnc-container */
  .tnc-logo {
    display: block;
    margin: 0 auto 20px;
    max-width: 150px;
    height: auto;
  }
  
  .tnc-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .tnc-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.8; /* Slightly increased line-height for better readability */
    margin-bottom: 20px;
    text-align: justify; /* Justify alignment for paragraphs */
  }
  
  .tnc-subheading {
    font-size: 1.7rem; /* Slightly larger for full-width screens */
    margin-top: 40px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .tnc-list {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
    text-align: justify; /* Justify alignment for list items */
  }
  
  .tnc-item {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
    text-align: justify; /* Justify alignment for list items */
  }
  